import {Stage} from "src/constants/common";
import {isKrakenRolloutPath} from "@amzn/atoz-kraken-utility";

export const STAGE_NAME = 'prod';
// skip loading react in testing for now. So that proper unit tests can exist cleanly and without excessive load time
// TODO: continue https://taskei.amazon.dev/tasks/V1334014338 and determine a way to use RTL
//  for testing without disabling the ability to test non-visible requirements
if(!global.skipRTL){
    console.log("Stage name " + STAGE_NAME);

    //Sets the right stage based CDN URL for FAB to be consumed via Module Federation.
    // @ts-ignore
    if (STAGE_NAME.toLowerCase() === Stage.prod) {
        (window as any).FAB_URL = 'https://atoz-livehelp-app.amazon.work';
    } else {
        (window as any).FAB_URL = 'https://atoz-livehelp-app.integ.amazon.work';
    }

    import('./bootstrap');
}

const BASE = isKrakenRolloutPath() ? '/neverland-myhr' : '/myhr';

export const MLE_ROUTES = {
    FPP_URL: BASE + "/fpp",
    LIFE_EVENT_NAME_URL: BASE + "/fpp/:lifeEventName",
    HELPFUL_RESOURCES_URL: BASE + "/fpp/:lifeEventName/helpfulResources",
    POLICY_URL: BASE + "/fpp/:lifeEventName/policy",
    ARTICLE_URL: BASE + "/fpp/:lifeEventName/article",
    MML_CASE_SELECT_URL: BASE + "/fpp/:lifeEventName/mmlCaseSelect",
    MML_WORKFLOW_URL: BASE + "/fpp/:lifeEventName/mmlWorkflow",
    WORKFLOW_URL: BASE + "/fpp/:lifeEventName/workflow",
    TASK_PAGE_COMPOUND_ID_URL: BASE + "/fpp/:lifeEventName/:compoundTaskId",
    TASK_PAGE_COMPOUND_ID_STEP_URL: BASE + "/fpp/:lifeEventName/:compoundTaskId/step/:stepId",
    LIFE_CATEGORY_PAGE_URL: BASE + "/mle/:lifeEventCategory",
    LIFE_CATEGORY_LIFE_EVENT_URL: BASE + "/mle/:lifeEventCategory/:lifeEventName",
    LIFE_CATEGORY_LIFE_EVENT_WORKFLOW_URL: BASE + "/mle/:lifeEventCategory/:lifeEventName/workflow",
    LIFE_CATEGORY_LIFE_EVENT_RESOURCES_URL: BASE + "/mle/:lifeEventCategory/:lifeEventName/helpfulResources",
    LIFE_CATEGORY_LIFE_EVENT_POLICY_URL: BASE + "/mle/:lifeEventCategory/:lifeEventName/policy",
    LIFE_CATEGORY_LIFE_EVENT_ARTICLE_URL: BASE + "/mle/:lifeEventCategory/:lifeEventName/article",
    LIFE_CATEGORY_LIFE_EVENT_MML_CASE_SELECT_URL: BASE + "/mle/:lifeEventCategory/:lifeEventName/mmlCaseSelect",
    LIFE_CATEGORY_LIFE_EVENT_MML_WORKFLOW_URL: BASE + "/mle/:lifeEventCategory/:lifeEventName/mmlWorkflow",
    LIFE_CATEGORY_LIFE_COMPOUND_ID_URL: BASE + "/mle/:lifeEventCategory/:lifeEventName/:compoundTaskId",
    LIFE_CATEGORY_LIFE_COMPOUND_ID_STEP_URL: BASE + "/mle/:lifeEventCategory/:lifeEventName/:compoundTaskId/step/:stepId"
}

export {};